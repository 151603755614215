<template>
	<div class="details">
		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="@/assets/images/ceping/dengbao.jpg">
                </div>
				<div class="con_top_right">
					<h2>等保整改</h2>
					<p class="new_tit">帮助根据信息系统运营、使用单位前期的等级保护合规评估情况，进行安全整改、加固，达到国家等级保护标准规范要求。</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">在线咨询</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                            <p>
                                等级保护是国家政府法规规定、按等级保护要求进行安全保护、按等级保护要求进行建设和改进、按等级保护要求进行测评。
                            </p>
                        </div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>
		<div class="conten_body">  
			<div class="conten_left">
				<div class="content_img">
					<img src="@/assets/images/ceping/dengbao-1.jpg" alt="">
				</div>

				<div class="demand">
					<div class="demand_title">
						<div class="xian"></div>
						<div class="title">需求分析</div>
						<div class="xian"></div>
					</div>

					<div class="demand_content">
						<ul>
							<li>
								<div class="demand_img">
									<img src="@/assets/images/ceping/d1.jpg" alt="">
								</div>
								<div class="li_title"><h4> 等级保护是国家政府法规规定</h4></div>
								<div class="li_text">国家制定一系列网络安全保护法律法规，规定国家实行网络安全等级保护制度。</div>
							</li>
							<li>
								<div class="demand_img">
									<img src="@/assets/images/ceping/d2.jpg" alt="">
								</div>
								<div class="li_title"><h4> 按等级保护要求进行安全保护</h4></div>
								<div class="li_text">网络运营者应当按照网络安全等级保护制度的要求，履行安全保护义务，保障网络免收干扰、破坏或者未经授权的访问，防止网络数据泄露、篡写。</div>
							</li>
							<li>
								<div class="demand_img">
									<img src="@/assets/images/ceping/d3.jpg" alt="">
								</div>
								<div class="li_title"><h4> 按等级保护要求进行建设和改进</h4></div>
								<div class="li_text">运营、使用单位应用按照国家信息安全等级保护管理规范和技术标准,开展信息系统安全建设或者改建工作。</div>
							</li>
							<li>
								<div class="demand_img">
									<img src="@/assets/images/ceping/d4.jpg" alt="">
								</div>
								<div class="li_title"><h4>按等级保护要求进行测评</h4></div>
								<div class="li_text">信息系统建设完成后，运营、使用单位或者其主管部门应当定期对信息系统安全等级状况开展等级测计。</div>
							</li>
						</ul>
					</div>
				</div>

				<div class="service">
					<div class="service_title">
						<div class="xian"></div>
						<div class="title">等级保护加固服务</div>
						<div class="xian"></div>
					</div>
					<div class="service_content">
						<div class="left">
							<p>帮助根据信息系统运营、使用单位前期的等级保护合规评估情况，进行安全整改、加固，达到国家等级保护标准规范要求。</p>
						</div>
						<div class="right">
							<img src="@/assets/images/ceping/d6.jpg" alt="">
						</div>
					</div>

				</div>

				<div class="introduce">
					<div class="introduce_title">
						<div class="xian"></div>
						<div class="title">产品介绍-服务依据</div>
						<div class="xian"></div>
					</div>


					<div class="introduce_img">
						<img src="@/assets/images/ceping/d7.jpg" alt="">
					</div>
				</div>

				<div class="process">
					<div class="process_title">
						<div class="xian"></div>
						<div class="title">产品介绍-服务流程</div>
						<div class="xian"></div>
					</div>

					<div class="process_img">
						<div class="fuwu">服务内容</div>
						<img src="@/assets/images/ceping/d8.jpg" alt="">
					</div>

					<div class="process_content">
						<ul>
							<li>
								<div class="li_img">

								<img src="@/assets/images/ceping/d9.jpg" alt="">
								</div>
								<p>基于国家信息安全标准规范，权威可信</p>
							</li>
							<li>
								<div class="li_img">

									<img src="@/assets/images/ceping/d10.jpg" alt="">
								</div>
								<p>服务套餐多种选择，按需采购</p>
							</li>
							<li>
								<div class="li_img">

								<img src="@/assets/images/ceping/d11.jpg" alt="">
								</div>
								<p>专业信息安全服务团队服务，专业可靠</p>
							</li>
							<li>
								<div class="li_img">

								<img src="@/assets/images/ceping/d12.jpg" alt="">
								</div>
								<p>资深安全专家博士带队，经验丰富</p>
							</li>
						</ul>
					</div>

					<div class="return_img">
						<img src="@/assets/images/ceping/d14.jpg" alt="">
					</div>
				</div>


				
            
        



				

			</div>
			<!-- right -->
			<div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/guangdong">
                                            <img src="@/assets/images/record/guangdong.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>广东省增值电信业务</p>
                                        <p>1、服务团队从业经...</p>
                                        <p>￥8000-40000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/ability">
                                            <img src="@/assets/images/app/007.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>APP安全能力第三方评估报告</p>
                                        <p>App、安全评估报告...</p>
                                        <p>￥9800</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
			
		</div>
	</div>
</template>

<script type="text/javascript">
export default{

data(){
	return{
		transmitData:{
			title:"云上业务安全无忧保障计划初级套餐",
			content:"",
			price:'0',
			url:require('@/assets/images/yunsafe/222.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}


.content_img{
	width: 100%;
	height: 100%;
	img{
		width: 100%;
		height: 100%;
	}
}

.demand{
	margin-top: 30px;
	.demand_title{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		.xian{
			width: 200px;
			border: 1px solid #5250CC;
		}
		.title{
			font-size: 24px;
			color: #5250CC;
			font-weight: bold;
		}
	}
	.demand_content{
		margin-top: 30px;
		border: 1px solid black;
		ul{
			display: flex;
			li{
				width: 25%;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 10px 30px;
				border-right: 1px dashed black;
				.demand_img{
					width: 150px;
					height: 150px;
					img{
						width: 100%;
						height: 100%;
					}
				}
				.li_title{
					margin: 20px 0;
					h4{
						font-size: 20px;
					line-height: 22px;
					font-weight:540;
					}
					
				}
				.li_text{
					font-size: 16px;
					line-height: 20px;
				}
			}
			:nth-last-child(1){
				border: 0px;
			}
		}
	}
}

.service{
	margin-top: 100px;
	.service_title{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.xian{
			width: 200px;
			border: 1px solid #5250CC;

		}
		.title{
			padding: 0 50px;
			font-size: 24px;
			color: #5250CC;
			font-weight: bold;
		}
	}
	.service_content{
		margin-top: 50px;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		.left{
			width: 241px;
			height: 144px;
			p{
				text-indent: 2em;
				font-weight: 500;
				font-size: 19px;
				line-height: 28px;
			}
		}
		.right{
			width: 273px;
			height: 209px;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
}

.introduce{
	margin-top: 100px;
	.introduce_title{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.xian{
			width: 200px;
			border: 1px solid #5250CC;

		}
		.title{
			padding: 0 50px;
			font-size: 24px;
			color: #5250CC;
			font-weight: bold;
		}
	}

	.introduce_img{
		margin-top: 50px;
		width: 864px;
		height: 583px;
		img{
			width: 100%;
			height: 100%;
		}
	}
}

.process{
	margin-top: 100px;
	.process_title{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.xian{
			width: 200px;
			border: 1px solid #5250CC;

		}
		.title{
			padding: 0 50px;
			font-size: 24px;
			color: #5250CC;
			font-weight: bold;
		}
	}
	.process_img{
		width: 632px;
		height: 245px;
		border: 1px dashed black;
		position: relative;
		margin: 80px auto;
			text-align: center;
		.fuwu{
			position: absolute;
			top: -16px;
			left: 30px;
			font-size: 22px;
			margin: auto;
			font-weight: bold;
			z-index: 100;
		}
		img{
			border: 1px solid black;
			width: 542px;
			height: 177px;
			margin: 50px;
		}
	}
	.process_content{
		ul{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			li{
				width: 50%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.li_img{
					width: 100px;
					height: 100px;
					img{
						width: 100%;
						height: 100%;
					}
				}
				p{	
					text-align: center;
					padding: 50px 130px;
					font-size: 20px;
					line-height: 22px;
				}
			}
		}
	}
	.return_img{
		width: 863px;
		height: 602px;
		img{
			width: 100%;
			height: 100%;
		}
	}
}










.conten_right{
	width:300px;
	background:#f4f4f4;
	float:right;
  display: block;
  min-height: 100%;
	margin-left:8px;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #00A9F2;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #00A9F2;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:147px;
                            height:116px;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 25px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 48px;
                            font-size: 13px;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}

.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#00A9F2;
	line-height: 18px;
	font-size:16px;
    padding:10px;
	color:#fff;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:20px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #C10909;
	line-height: 47px;
    padding-left: 45px;
    padding-right: 10px;
}
.pri_box  .price{
	font-size: 24px;
	font-weight: bold;
	color: #C10909;
	position: relative;

}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 15px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        p{
            font-size: 16px;
            line-height: 18px;
        }
    }
}
.box_ti{
   margin-top:25px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:25px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.details{
	width:1200px;
	margin:0 auto;

//**/





}


</style>







